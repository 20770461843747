// extracted by mini-css-extract-plugin
export var dark = "blog-module--dark--2GMvb";
export var theme = "blog-module--theme--1IWba";
export var headerWrapper = "blog-module--headerWrapper--1VrR1";
export var headerLink = "blog-module--headerLink--29EgB";
export var sidebarToggleBtn = "blog-module--sidebarToggleBtn--3919E";
export var footer = "blog-module--footer--3rnEv";
export var sidebarContent = "blog-module--sidebarContent--8A0ob";
export var accordion__button = "blog-module--accordion__button--3VC52";
export var sidebarLink = "blog-module--sidebarLink--18xeG";
export var heroButtons = "blog-module--heroButtons--3briE";
export var button = "blog-module--button--3rn5P";
export var hero = "blog-module--hero--1q9a9";
export var post = "blog-module--post--1xxQA";
export var postNew = "blog-module--postNew--Qt_RX";
export var searchInput = "blog-module--searchInput--8v3Ee";
export var socialLink = "blog-module--socialLink--332mX";
export var tags = "blog-module--tags--2FgLV";
export var tag = "blog-module--tag--1MQWn";
export var postTitle = "blog-module--postTitle--3seEU";
export var blogPost = "blog-module--blogPost--1BNqJ";
export var newBadge = "blog-module--newBadge--D7Ug1";
export var light = "blog-module--light--3rQw5";
export var desktopOnly = "blog-module--desktopOnly--GSZnM";
export var container = "blog-module--container--3PF9Q";
export var large = "blog-module--large--3SdwK";
export var small = "blog-module--small--3LDeF";
export var title = "blog-module--title--AUldz";
export var subTitle = "blog-module--subTitle--34_UI";